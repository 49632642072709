.news-list.scroll {
    overflow-y: clip;
    overflow-x: scroll;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    padding-bottom: 1rem;

    .flex-large {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .news {
        &-single {
            display: flex;
            width: 800px;
            background-color: $color-background-grey;

            >.full-container {
                width: 50%;
            }

            &:hover {
                text-decoration: none !important;
            }

            &.no-image {
                width: 400px;
                height: 100%;

                >.full-container {
                    width: 100%;
                }

                .news-data {
                    width: 100% !important;
                    min-height: 255px;
                    padding: 46px 30px;
                    .news-category{
                        text-transform: uppercase;
                        color:  $color-third;
                        font-family: klavika-web-condensed, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .news-title{
                        font-size: 21px;
                        font-weight: 400;
                        color: #1F2833;
                        font-family: 'Titillium Web', sans-serif;
                    }

                    .news-subtitle{
                        font-size: 16px;
                        font-weight: 400;
                        color: #1F2833;
                        font-family: 'Titillium Web', sans-serif;
                    }
                    // background-color: yellow;
                }
            }
        }

        &-info {
            hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border-top: 1px solid $color-light-grey;
            }

            .news-subtitle {
                font-size: 16px;
            }
        }

        &-data {
            width: 50%;
            padding: 1.2rem 2rem;
        }
    }

    &::-webkit-scrollbar {
        height: 4px;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        background: rgba($color: #f1f1f1, $alpha: 0.4);
    }

    &::-webkit-scrollbar-thumb {
        background: $color-secondary;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $color-secondary;
    }
}

.news-list.grid {
    .news-solo {
        margin-bottom: 2rem;
        // flex-basis: 50% !important;
    }

    .news {
        &-single {
            &:hover {
                text-decoration: none !important;
            }
        }

        &-data {
            height: 270px;
            position: relative;

            .float-right {
                position: absolute;
                right: 20px;
                bottom: 15px;
            }
        }
    }

}


.container {
    .news-list.scroll {
        margin-right: 0;
        margin-left: 0;
    }
}

.news-pagination.scroll,
.news-pagination.grid {
    text-align: center;
    margin-top: 2rem;

    span {
        font-family: 'Titillium Web', sans-serif;
        ;
    }

    .news-previous {
        position: relative;

        &::before {
            z-index: 100;
            content: '';
            position: absolute;
            width: 15px;
            height: 23px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9 14' style='enable-background:new 0 0 9 14;' width='15' width='9' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%2335C4B5;stroke-width:2;%7D%0A%3C/style%3E%3Cpath id='Path_22' class='st0' d='M7.7,0.7L1.4,7.1l6.3,6.3'/%3E%3C/svg%3E%0A");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: -40px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .news-next {
        position: relative;

        &::before {
            z-index: 100;
            content: '';
            position: absolute;
            width: 15px;
            height: 23px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9 14' style='enable-background:new 0 0 9 14;' width='15' width='9' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%2335C4B5;stroke-width:2;%7D%0A%3C/style%3E%3Cpath id='Path_22' class='st0' d='M1.4,13.4l6.3-6.3L1.4,0.7'/%3E%3C/svg%3E%0A");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: 25px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: $small) {
    .news-list.scroll {
        .news {
            &-single {
                flex-direction: column;
                width: 380px;

                >.full-container {
                    width: 100%;
                }
            }

            &-data {
                width: 100%;
            }
        }
    }

    .news-list.grid {
        .news-solo {
            flex-basis: 100% !important;
            padding: 0;
        }
    }
}

@media screen and (max-width: $large) {
    .news-list.grid {
        .news-solo {
            flex-basis: 50% !important;
            padding: 0;
            margin-bottom: 3rem;
        }
    }
}

@media screen and (max-width: $medium) {
    .news-list.grid {
        .news-solo {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}