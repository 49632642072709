.adam__form {
    font-family: 'Titillium Web', sans-serif;

    &__row {
        display: flex;
        width: 100%;
    }

    &__column {
        width: 50%;
    }

    &__fieldset {
        margin-bottom: 30px;

        & > legend {
            color: $color-border;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 15px;
            padding: 0;
        }
    }

    &__group {
        margin-bottom: 30px;
    }

    &__label {
        color: $color-border;
    }

    &__button {
        border: none;
        border-radius: 0;
        color: $color-secondary;
        background-color: $color-active;
        font-size: 13px;
        font-weight: 400;
        float: right;
        padding: 15px 18px;
        text-align: left;
        text-transform: uppercase;
        transition: all .3s;
        min-width: 200px;

        &:hover, &:active, &:focus {
            border: none;
            color: $color-white;
            background-color: $color-secondary;
        }
    }

    &__input {
        color: $color-white;
        padding: 16px 0;

        &::placeholder {
            color: $color-grey;
        }

        &, &:hover, &:focus, &:active {
            background: none;
            border: none;
            border-bottom: 1px solid $color-primary-light;
            border-radius: 0;
            box-shadow: none;
        }
    }

    &__checkbox {
        font-size: 1rem;
        font-weight: 400;
        position: relative;

        p {
            color: $color-grey;
            display: inline-block;
            line-height: 20px;
            margin-left: 25px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            & + span {
                border: 1px solid $color-primary-light;
                display: inline-block;
                position: absolute;
                height: 14px;
                width: 14px;
                top: 5px;

                &::after {
                    content: "";
                    display: none;
                    position: absolute;
                }
            }

            &:checked + span::after {
                display: block;
            }
        }

        input[type="checkbox"] {
            & + span::after {
                border: solid $color-secondary;
                border-width: 0 2px 2px 0;
                border-radius: 1px;
                left: 3.33px;
                top: 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                height: 10px;
                width: 6px;
            }
        }

        input[type="radio"] {
            & + span {
                border-radius: 50px;

                &::after {
                    background: $color-secondary;
                    border-radius: 50%;
                    left: 2px;
                    top: 2px;
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }

    &__languages {
        color: $color-grey;

        tr {
            th {
                border-bottom: 1px solid $color-primary-light;
                font-weight: 400;
                padding: 15px;
                text-align: center;
                width: 17.5%;

                &:first-child {
                    width: auto;
                }
            }

            td {
                border: none;
                padding: 10px;
                text-align: center;

                &:first-child {
                    text-align: left;
                }
            }
        }
    }

    &.form--newsletter {
        .adam__form {
            &__input {
                &, &:hover, &:focus, &:active {
                    border-bottom: 1px solid $color-light-grey;
                }
            }
            
            &__checkbox {
                input[type="checkbox"],
                input[type="radio"] {
                    & + span {
                        border: 1px solid $color-light-grey;
                    }
                }
            }
        }

        .choices {
            > .choices__inner {
                border-bottom: 1px solid $color-light-grey;
            }
        }

        .choices > .choices__list,
        .choices.is-flipped > .choices__list {
            &--dropdown {
                background-color: $color-light-grey;
            }
        }
    }
}

.adam__alert {
    &.alert--hidden {
        display: none;
    }
}

.choices {
    &__placeholder {
        color: $color-grey;
    }

    & > &__inner {
        background: none;
        border: none;
        border-bottom: 1px solid $color-primary-light;
        border-radius: 0;
        font-size: inherit;

        & > .choices__list {
            padding: 0;
        }

        & > .choices__input {
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: $color-grey;
            display: inline-block;
            padding: 16px 0;
        }
    }

    & > &__list,
    &.is-flipped > &__list {
        &--dropdown {
            background-color: $color-primary-light;
            border: none;
            border-radius: 0;
            color: $color-border;

            .choices__item {
                &--selectable.is-highlighted {
                    background-color: $color-active;
                }
            }
        }

        & > .choices__input {
            background-color: transparent;
            border: none;
            border-radius: 0;
            box-shadow: none;
            color: $color-white;
        }
    }

    &[data-type*="select-one"] {
        & > .choices__inner {
            line-height: 1.55rem;
            padding: 16px 0;

            .choices__button {
                display: none;
            }
        }

        &::after { 
            border-color: $color-secondary;
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: "";
            display: inline-block;
            height: 12px;
            margin: 0;
            position: absolute;
            right: 12px;
            top: 20px;
            transform: rotate(135deg);
            width: 12px;
        }
    }

    &[data-type*="select-multiple"],
    &[data-type*="text"] {
        & > .choices__inner {
            padding: 0;

            .choices__item {
                border: none;
                background-color: $color-active;
            }

            .choices__button {
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 8px;
                border: none;
                border-left: 1px solid $color-white;
                padding: 0 0 0 16px;
                vertical-align: bottom;
            }
        }
    }
}

.datepicker {
    span {
        border-radius: 0;
    }

    &-picker {
        background-color: $color-primary-light;
        border-radius: 0;
        color: $color-border;
    }

    &-input {
        &, &.in-edit {
            &, &:active, &:focus {
                box-shadow: none;
            }
        }
    }

    &-controls {
        .button {
            border-radius: 0;
            background-color: $color-primary-light;
            color: $color-border;
        }
    }

    &-cell {
        &.selected, &.selected:hover, &.focused, &:focus, &:hover {
            &, &:not(.selected) {
                background-color: $color-active;
                color: $color-white;
                font-weight: 400;
            }
        }

        &.prev:not(.disabled),
        &.next:not(.disabled) {
            color: $color-grey;
        }
    }
}