.mc__products {
    font-family: 'Titillium Web', sans-serif;

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 60px;
    }

    .mc__filters {
        display: flex;
        gap: 30px;
        justify-content: space-evenly;
        margin-bottom: 60px;
    }

    .mc__select {
        align-items: center;
        background-color: $color-white;
        border: 1px solid $color-light-blue;
        display: grid;
        grid-template-areas: "select";
        line-height: 1.1;
        min-width: 15ch;
        width: 100%;

        &:after,
        &__input {
            grid-area: select;
        }

        &:after {
            content: '';
            cursor: pointer;
            border: solid $color-secondary;
            border-width: 0 3px 3px 0;
            display: block;
            margin-right: 10px;
            padding: 3px;
            justify-self: end;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 9px;
        }

        &__input {
            appearance: none;
            background: none;
            border: none;
            cursor: pointer;
            padding: 8px 16px;
            margin: 0;
            outline: none;
            width: 100%;

            &::-ms-expand {
                display: none;
            }
        }
    }

    .mc__filter {
        flex: 0 1 25%;

        &.filter--power {
            flex-basis: 50%;

            label {
                margin-bottom: 50px;
            }
        }

        label {
            color: $color-secondary;
            margin-bottom: 30px;
            text-transform: uppercase;
        }
    }

    .mc__product {
        &.product--hidden {
            display: none;
        }

        & > a {
            &:focus, &:hover, &:active {
                text-decoration: none;
            }
        }

        &__title {
            border-top: 2px solid $color-light-blue;
            color: $color-secondary;
            margin-bottom: 15px;
            padding-top: 20px;
            text-transform: uppercase;
        }

        &__image {
            margin-bottom: 15px;
            width: 100%;
        }

        &__button {
            border: 0;
            border-radius: 0;
            background-color: $color-secondary-dark;
            color: $color-secondary;
            font-family: klavika-web-condensed, sans-serif;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 1px;
            margin: 0;
            padding: 15px;
            text-transform: uppercase;

            &:active, &:hover {
                background-color: $color-secondary;
                color: $color-white;
                transition: all .3s;
            }
        }
    }

    @media (max-width: $medium) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }

        .mc__filters {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    @media (max-width: $small) {
        &__list {
            grid-template-columns: 1fr;
        }

        .mc__filter {
            &, &.filter--power {
                flex-basis: 100%;
            }
        }
    }
}