.adam__modal {
    color: $color-primary;
    display: none;
    font-family: 'Titillium Web', sans-serif;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    outline: 0;
    z-index: 1072;

    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    &::before {
        content: ' ';
        background-color: $color-primary;
        display: block;
        position: absolute;
        opacity: .5;
        height: 100%;
        width: 100%;
    }

    &__show {
        overflow: hidden;
    }

    &__dialog {
        background-color: $color-white;
        left: 50%;
        top: 50%;
        padding: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
        overflow-y: auto;
        max-height: 90%;
        width: 100%;
        z-index: 1073;

        &.modal--button {
            max-width: 600px;
        }
    }

    &__close {
        border-color: transparent;
        border-radius: 500px;
        font-size: 1.5rem;
        padding: 2px 7px;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &.modal--open {
        display: block;
    }
}