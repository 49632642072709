//
// TEXT
//

.text-primary {
	color: $color-primary !important;
}

.text-secondary {
	color: $color-secondary !important;
}

.title-primary {
	font-family: klavika-web-condensed, sans-serif;
	color: $color-primary;
	letter-spacing: 2.61px;
}

.title-secondary {
	font-family: klavika-web-condensed, sans-serif;
	letter-spacing: 1.7px;
	font-weight: 400;
	color: $color-secondary;
	text-transform: uppercase;
}

.sub-title {
	font-family: klavika-web-condensed, sans-serif;
	color: $color-primary;
}

.text-white {
	color: white !important;

	span {
		color: white !important;
	}
}

.text-blue {
	color: $color-blue !important;

	span {
		color: $color-blue !important;
	}
}

.text-semi-white {
	color: $color-light-grey !important;

	span {
		color: $color-light-grey !important;
	}
}

.text-grey {
	color: $color-grey !important;

	span {
		color: $color-grey !important;
	}
}

.text-dark {
	color: $color-primary-light !important;

	span {
		color: $color-primary-light !important;
	}
}


.big-font-ferrari,
.big-font-ferrari-circle {
	font-family: klavika-web-condensed, sans-serif;
	font-size: 148px;
	color: transparent !important;
	text-stroke: 2px $color-secondary;
	-webkit-text-stroke: 2px $color-secondary;
	-webkit-text-fill-color: transparent;
	line-height: 124px;
	font-weight: 500;
}

.date{
	.big-font-ferrari,
	.big-font-ferrari-circle {
		font-size: 90px !important;
	}
}

.big-font-ferrari {
	letter-spacing: 4.44px;
	text-stroke: 2px #007C92;
	-webkit-text-stroke: 2px #007C92;
}

.big-font-ferrari-circle {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		background-color: transparent;
		border: 2px solid $color-secondary;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		top: calc(50% - 100px);
		left: calc(50% - 100px);
	}
}

.suggestion {
	// color: $color-primary-light;
	font-family: klavika-web-condensed, sans-serif;
	font-size: 40px;
	font-weight: 500;
	line-height: 48px;
}


//  BUTTONS 

.btn {
	background-color: $color-secondary-dark;
	padding: 15px 80px;
	margin: 0.5rem 0.5rem 0.5rem 0;
	transition: all 0.3s;
	font-family: 'Titillium Web', sans-serif;
	-webkit-appearance: none;
	display: inline-block;
	margin: 0 0 0.5rem;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	font-weight: 500;
}

.button-primary-white-center {
	@extend .btn;
	color: white;
	font-size: 17px;
}




.button-primary-white-left {
	@extend .btn;
	color: white;
	font-size: 17px;
	padding: 17px 129px 16px 50px;
}

.button-primary-white-center,
.button-primary-white-left {
	transition: all 0.3s;

	&:hover {
		background-color: $color-secondary;
	}
}

.button-primary-white-center-small {
	@extend .button-primary-white-center;
	padding: 17px 35px 16px 35px;
}

.button-primary-green-center {
	@extend .btn;
	color: #66fcf1;
	font-size: 14px;
	padding: 15px 77px;
}

.button-primary-green-left {
	@extend .btn;
	color: #66fcf1;
	font-size: 14px;
	padding: 17px 119px 16px 50px;
}

.button-primary-green-center,
.button-primary-green-left {
	transition: all 0.3s;
	font-weight: 600;

	&:hover {
		color: white;
		background-color: $color-secondary;
	}
}

//  BUTTONS 


//  BACKGROUNDS 


.background-white {
	background-color: white;
}

.background-primary-alternate {
	background-color: $color-primary-light;
}

.background-secondary-alternate {
	background-color: $color-secondary-dark;
}

.title-middle {
	font-weight: 500;
	line-height: 60px;
	font-size: 65px;
}


.background-primary-bubbles {
	background-color: $color-primary;
	position: relative;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		top: -230px;
		left: -200px;
		background: radial-gradient(circle, #35c4b619 0%, transparent 60%);
		width: 750px;
		height: 750px;
		z-index: 0;
	}

	&::after {
		content: "";
		position: absolute;
		bottom: -230px;
		right: -200px;
		background: radial-gradient(circle, #35c4b619 0%, transparent 60%);
		width: 750px;
		height: 750px;
		z-index: 0;
	}

	>div,
	section {
		position: relative;
		z-index: 1;
	}
}

.background-primary-bubbles-content {
	background-color: transparent;
	position: relative;
	padding: 0;

	&::before {
		content: "";
		z-index: 0;
		position: absolute;
		display: block;
		top: 50%;
		left: calc(50% - 450px);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		height: 900px;
		width: 900px;
		background: radial-gradient(circle, #005a8b32 0%, transparent 60%);
	}

	>div,
	section {
		position: relative;
		z-index: 1;
	}
}


//  BACKGROUNDS 


@each $name,
$value in $item-padding-scale {
	.item-padding-scale-#{$name} {
		.adam-column-row {
			.neos-contentcollection {
				section {
					margin-right: $value;

				}
			}
		}
	}
}

@media screen and (max-width: $medium) {
	.wrap-tablet {
		.adam-column-row {
			>.neos-contentcollection {
				flex-wrap: wrap;
			}
		}
	}
}

@media screen and (max-width: $small) {

	.wrap-mobile,
	.wrap-tablet {
		.adam-column-row {
			>.neos-contentcollection {
				flex-wrap: wrap;

				>section {
					margin-right: 30px;
					margin-bottom: 40px;
				}
			}
		}
	}

	.big-font-ferrari,
	.big-font-ferrari-circle {
		font-size: 82px;
		line-height: 80px;
	}

	.title-middle {
		font-weight: 500;
		line-height: 48px;
		font-size: 44px;
	}
}


// .button-round-button {
// 	@extend .button;
// 	@extend .round-button;
// }

// .button-muted-button-round-button {
// 	@extend .button;
// 	@extend .muted-button;
// 	@extend .round-button;
// }

// .button-accent-button-round-button {
// 	@extend .button;
// 	@extend .accent-button;
// 	@extend .round-button;
// }