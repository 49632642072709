// Generic elements
body {
    font-family: 'Titillium Web', sans-serif;
    color: $color-primary;
}

ul,
ol {
    padding-left: 20px;

    li::marker {
        font-weight: 700;
    }
}

hr {
    border-color: $color-border;
}

// Header

// Foooter
footer {
    .newsletter {
        margin-bottom: -120px;
        position: relative;
        z-index: 20;

        &>div {
            padding: 60px 30px;
        }
    }

    &>div>.full-container,
    a {
        background-color: $color-primary;
        color: $color-light-grey;
    }
}

// Questa riga creava overflow in alcuni casi. Modifica che sarebbe anche da riportare in tutto gli altri progetti NEOS
.adam__text-wrapper.no-text {
    display: none;
}

.adam-text li {
    line-height: 1.5rem;
}

.background-primary,
.background-primary-alternate,
.background-primary-bubbles,
.background-primary-bubbles-content,
.background-secondary,
.background-secondary-alternate {

    ul,
    ol {
        li::marker {
            color: $color-light-grey;
        }
    }
}

.custom-container.full-container {
    margin: auto;
    max-width: 1700px;
}

@media screen and (min-width: $large) {
    .custom-three-columns {
        >.three-columns {
            .column-one {
                flex-basis: 20%;
            }

            .column-two {
                flex-basis: 39%;
                padding: 0 60px;
            }

            .column-three {
                flex-basis: 40%;
                padding-left: 30px;
                padding-right: 0;
            }
        }
    }

    .two-columns {
        .column-one {
            padding-right: 2rem;
        }

        .column-two {
            padding-left: 2rem;
        }
    }
}

@media screen and (max-width: $medium) {
    .custom-container.full-container {
        padding: 0 2rem;
        max-width: 1000px;

        .twoThirdsRight {
            .two-columns-column {
                flex-basis: 100% !important;
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .custom-three-columns {
        >.three-columns {
            .three-columns-column {
                flex-basis: 100%;
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .row-footer {
        >.wrapper {
            >.adam-column-row {
                >.neos-contentcollection {
                    flex-wrap: wrap;

                    section {
                        flex: 0 0 50%;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $small) {
    .row-footer {
        >.wrapper {
            >.adam-column-row {
                >.neos-contentcollection {
                    section {
                        flex: 0 0 100%;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: $medium) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2em !important;

        span {
            display: inline-block !important;
            line-height: inherit !important;
        }
    }


}

.d-block {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h3,
    .h4,
    .h5,
    .h6 {
        display: block !important;
    }
}

.ferrari-tabella {
    overflow: scroll;
}


#newsletter {
    position: relative;

    label[for="newsletter-privacy"] {
        margin-bottom: 20px;

        a {
            color: #acbcc8;

            &:hover {
                color: #acbcc8;
            }
        }
    }

    .help-inline {
        position: absolute;
        margin-top: 25px;
        left: 23px;
    }

    @media screen and (max-width: 470px) {
        label[for="newsletter-privacy"] {
            margin-bottom: 40px;
        }

        .help-inline {
            margin-top: 80px;
        }
    }

    
    input[type="submit"], .btn-primary{
        border-radius: 0 !important;
        border-color: transparent !important;
        &:hover{
            border-color: transparent !important;
        }
    }

}


#contatto {
    max-width: 600px;
    margin: auto;
    position: relative;

    .clearfix.input:nth-child(6) {
        display: flex;
        flex-direction: row-reverse;

        .help-inline {
            position: absolute;
            margin-top: 25px;
            left: 23px;
        }

    }

    label[for="contatto-privacy"] {
        margin-left: 10px;
        margin-bottom: 30px;
    }

    input[type="submit"], .btn-primary{
        border-radius: 0 !important;
        border-color: transparent !important;
        &:hover{
            border-color: transparent !important;
        }
    }

    @media screen and (max-width: 470px) {
        .clearfix.input:nth-child(6) {

            .help-inline {
                position: relative;
            }
        }
    }
}


.language-menu{
    color: $color-secondary;
    position: relative;
    ul{
        position: absolute;
        background-color: white;
    }
    li{
        span, .menu-item-link {
            color: $color-secondary !important;
        }
        color: $color-secondary;
        a{
            color: $color-secondary;
        }
    }
}