header {
    color: $color-grey;
    padding: 50px 0;
    position: absolute;
    width: 100%;
    z-index: 10;

    @media screen and (max-width: $medium) {
        padding-top: 20px;
    }


    &.header--fixed {
        background-color: $color-primary;
        padding: 0;
        position: fixed;

        @media screen and (max-width: $medium) {
            padding: 10px 0;
        }
        .brand img {
            max-width: 140px;
        }
    }

    .mc__navbar {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .brand img {
            width: auto;
            @media screen and (max-width: $large) {
                max-width: 240px;
            }
            @media screen and (max-width: $medium) {
                max-width: 200px;
            }
        }

        &__mobile-toggle {
            font-size: 2rem;
            display: none;
            margin: 0;
            padding: 0;
        }

        &__menu.menu--desktop {
            display: block;
        }
        
        &__menu.menu--mobile {
            display: none;
            position: absolute;

            &.menu--show {
                display: none;
            }
        }
    }

    .mc__list {
        display: flex;
        list-style: none;
        justify-content: space-between;
        padding: 0;
        text-transform: uppercase;

        &__item {
            position: relative;
        }

        &__sub {
            background-color: $color-primary-light;
            display: none;
            list-style: none;
            padding: 10px 0;
            position: absolute;
            right: 10px;
            top: 81px;

            &.sub--click,
            &.sub--hover {
                display: block;
            }
        }

        &>li>a,
        &__sub>li>a {
            color: $color-secondary;
            display: block;
            font-size: .8125rem;
            font-weight: 400;
            padding: 30px 20px;
            white-space: nowrap;

            &:active, &:hover, &:focus {
                color: $color-secondary;
                text-decoration: none;
            }

            .icon-chevron-down {
                color: $color-secondary;
                font-size: .9rem;
                margin-left: 5px;
            }
        }

        &__sub>li>a {
            padding: 0 20px;
        }
    }

    @media (max-width: $medium) {
        .mc__navbar {
            &__menu.menu--desktop {
                display: none;
            }

            &__menu.menu--mobile {
                background-color: white;
                right: 20px;
                top: 110px;
                &.menu--show{
                    display: block;
                }
                ul{
                    margin: 0;
                    flex-direction: column;
                    -webkit-box-shadow: 0px 0px 22px -1px rgba(0,0,0,0.16); 
                    box-shadow: 0px 0px 22px -1px rgba(0,0,0,0.16);
                    li{
                        a{
                            padding: 13px 40px;
                        }
                    }
                }
            }

            &__mobile-toggle {
                display: inline-block;
            }
        }
    }
}