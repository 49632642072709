.menu-footer {
    >.column-row {
        >.adam-column-row {
            >.neos-contentcollection {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-column-gap: 50px;

                >.full-container {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.img-outline {
    img {
        border: 1px solid $color-light-grey;
        transition: all 0.3s;

        &:hover {
            border: 1px solid $color-primary;
        }
    }
}

.pdf,
.zip {
    .adam-text {
        border-bottom: 1px solid $color-light-grey;
        display: flex;
        padding-bottom: 10px;
        position: relative;
        justify-content: space-between;

        &::after {
            background-color: $color-secondary;
            border-radius: 4px;
            color: $color-white;
            content: "PDF";
            font-family: 'Titillium Web', sans-serif;
            font-size: 13px;
            font-weight: 700;
            height: 23px;
            padding: 4px;
            position: relative;
            margin-left: 5px;
            text-transform: uppercase;
        }
    }
}

.pdf-list {
    >.column-row {
        >.adam-column-row {
            >.neos-contentcollection {
                flex-wrap: wrap;

                section {
                    flex: 0 0 15%;
                    margin-bottom: 1rem;
                }

                @media screen and (max-width: $medium) {
                    section {
                        flex: 0 0 25%;
                        margin-bottom: 1rem;
                    }
                }

                @media screen and (max-width: $small) {
                    justify-content: space-between;

                    section {
                        flex: 0 0 45%;
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}

.zip {
    .adam-text {
        &::after {
            content: "ZIP";
            padding: 4px 6px;
        }
    }
}

.text-center {
    text-align: center;
}

main {
    padding-top: 0;

    &.fixed {
        padding-top: 100px;
    }
}

.check-property {
    p {
        color: $color-secondary;
        font-family: klavika-web-condensed, sans-serif;
        font-size: 26px;
        font-weight: 500;
        position: relative;

        &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 23 23' style='enable-background:new 0 0 23 23;' width='26' height='26' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23007C92;%7D .st1%7Bfill:none;stroke:%2335C4B5;stroke-width:2;%7D%0A%3C/style%3E%3Cg id='Group_5' transform='translate(-602 -582)'%3E%3Ccircle id='Ellipse_1' class='st0' cx='613.5' cy='593.5' r='11.5'/%3E%3Cpath id='Path_9' class='st1' d='M609.5,593.4l2.8,2.8l5.8-5.8'/%3E%3C/g%3E%3C/svg%3E%0A");
            content: '';
            position: absolute;
            left: -37px;
            height: 26px;
            width: 26px;
        }
    }
}

.testimonial {
    p {
        color: $color-light-grey;
        font-family: klavika-web-condensed, sans-serif;
        font-size: 40px;
        line-height: 48px;
    }
}

.spacing1-4 {
    letter-spacing: 1.4px !important;
}

.download {
    span {
        position: relative;

        &::after {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 28 28' style='enable-background:new 0 0 28 28;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%2335C4B5;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cg id='Icon_feather-download' transform='translate(-3.5 -3.5)'%3E%3Cpath id='Path_3021' class='st0' d='M30,21v6c0.1,1.6-1.2,2.9-2.8,3H7.8c-1.6-0.1-2.8-1.4-2.8-3v-6'/%3E%3Cpath id='Path_3022' class='st0' d='M11,14.4l6.5,7.5l6.5-7.5'/%3E%3Cpath id='Path_3023' class='st0' d='M17.5,23V5'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            content: "";
            height: 20px;
            margin-left: 15px;
            position: absolute;
            top: 0;
            width: 20px;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.empty-list-dot {
    ul {
        list-style-type: circle;
    }
}

.small-button {
    .button-primary-white-center,
    .button-primary-white-left,
    .button-primary-green-center,
    .button-primary-green-left {
        padding: 15px 20px;
    }
}

@media screen and (max-width: $medium) {
    .menu-footer {
        padding: 0;

        >.column-row {
            >.adam-column-row {
                >.neos-contentcollection {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-column-gap: 30px;
                    grid-row-gap: 30px;

                    >.full-container {
                        margin-right: unset;
                        margin-left: unset;
                    }
                }
            }
        }
    }

    .img-200 {
        img {
            max-width: 200px !important;
        }
    }
}

@media screen and (max-width: $small) {
    .menu-footer {
        >.column-row {
            >.adam-column-row {
                >.neos-contentcollection {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 60px;
                }
            }
        }
    }

    .big-number {
        margin-bottom: 90px;
    }

    .four-column-spacing {
        img {
            padding: 10px;
        }
    }
}

@media screen and (max-width: $x-small) {
    .menu-footer {
        >.column-row {
            >.adam-column-row {
                >.neos-contentcollection {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 30px;
                }
            }
        }
    }

    .check-items {
        .adam-column-row {
            .neos-contentcollection {
                display: block;

                .check-property {
                    margin: auto;

                    &:last-child {
                        margin-right: auto !important;
                    }
                }
            }
        }
    }
}

.logo-footer{
    max-width: 280px;
}

.form-footer{
    max-width: 420px;
    label[for=newsletter-email]{
        display: none;
    }
    label[for=newsletter-privacy]{
        margin-left: 10px;
    }
    .clearfix.input:nth-child(3){
        display: flex;
        flex-direction: row-reverse;
    }
}


.can-left{
    .adam-image{
        margin-left: -30%;
    }
}


.hand-spray-right{
    .adam-image{
        margin-right: -50%;
        @media screen and (max-width: $medium) {
            margin-right: -70%;
        }
        @media screen and (max-width: $small) {
            margin-right: -10%;
        }
    }
}

.text-center{
    text-align: center;
}

.m-auto{
    margin: auto;
}

.w-50{
    width: 50%;
    @media screen and (max-width: $medium) {
        width: 100%;
    }
}

.w-75{
    width: 75%;
    @media screen and (max-width: $medium) {
        width: 100%;
    }
}

.can-homepage{
    .adam-image{
        margin-bottom: -320px;
        @media screen and (max-width: $small) {
            margin-bottom: -80px;
        }
    }
}

.can-bottom{
    .adam-image{
        margin-bottom: -100px;
        @media screen and (max-width: $medium) {
            margin-bottom: 30px;
            
        }
    }
}
.colonna-quality{
    .two-columns{
        .column-one{
            @media screen and (max-width: $medium) {
                flex-basis: 100%;
            }
        }
    }
}


.quality-right{
    .adam-image{
        width: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.overflow-y-hidden{
    >div{
        overflow-y: hidden;
    }
}
.overflow-x-hidden{
    >div{
        overflow-x: hidden;
    }
}
.position-relative{
    >div{
        position: relative;
    }
}

td{
    border: 0;
}

.ferrari-table{
    border-collapse: unset;
    border-spacing: 4px;
    .ferrari-th{
        color: black;
        background-color: #C5C6C7;
        text-align: center;
        font-weight: 400;
    }
    .ferrari-td{
        text-align: center;
        color: black;
        background-color: #F2F6FB;
    }
}


.line-bottom{
    position: relative;
    &:after{
        content: "";
        width: 1px;
        background-color: $color-secondary;
        height: 100px;
        position: absolute;
        bottom: -82px;
        left: calc(50% - 2px);
        @media screen and (max-width: $medium) {
            bottom: -82px;
            height: 76px;
        }
        @media screen and (max-width: $x-small) {
            bottom: -132px;
            height: 106px;
        }
    }
    &.last{
        &:after{
            content: "";
            background-color: transparent;
            width: 13px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.414' height='113' viewBox='0 0 15.414 113'%3E%3Cline id='Line_27' data-name='Line 27' y2='112' transform='translate(7.707 0.5)' fill='none' stroke='%2345a29e' stroke-linecap='square' stroke-width='1' stroke-dasharray='10'/%3E%3Cline id='Line_28' data-name='Line 28' y1='7' x2='7' transform='translate(7.707 104.5)' fill='none' stroke='%2345a29e' stroke-linecap='square' stroke-width='1'/%3E%3Cline id='Line_29' data-name='Line 29' x1='7' y1='7' transform='translate(0.707 104.5)' fill='none' stroke='%2345a29e' stroke-linecap='square' stroke-width='1'/%3E%3C/svg%3E%0A");
            height: 100px;
            position: absolute;
            bottom: -82px;
            left: calc(50% - 8px);
            @media screen and (max-width: $medium) {
                width: 10px;
                bottom: -82px;
                height: 76px;
            }
            @media screen and (max-width: $x-small) {
                width: 14px;
                bottom: -132px;
                height: 106px;
            }
        }
    }
}

.numbers-div{
    @media screen and (max-width: $small) {
        .column-two{
            padding-top: 150px;
            text-align: center;
        }
    }
}



.video-home-container{
    position: absolute;
    top: 0;
    left: 0;
    height: 70vh;
    z-index: -1;
    width: 100%;
    @media screen and (max-width: $large) {
        display: none;
    }
    video{
        width: 100%;
    }
}


.video-home-container-mobile{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 70vh;
    z-index: -1;
    width: 100%;
    @media screen and (max-width: $large) {
        display: block;
    }
    video{
        width: 100%;
    }
}


.sezione-video{
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media screen and (max-width: $small) {
    .video-home-container-mobile, .video-home-container{
        display: none;
    }
    .sezione-video{
        background-color: $color-primary;
        position: relative;
        overflow: hidden;
        height: 100vh;
    

        &::before {
            content: "";
            position: absolute;
            top: -230px;
            left: -200px;
            background: radial-gradient(circle, #35c4b619 0%, transparent 60%);
            width: 750px;
            height: 750px;
            z-index: 0;
        }
    
        &::after {
            content: "";
            position: absolute;
            bottom: -230px;
            right: -200px;
            background: radial-gradient(circle, #35c4b619 0%, transparent 60%);
            width: 750px;
            height: 750px;
            z-index: 0;
        }
    
        >div,
        section {
            position: relative;
            z-index: 1;
        }
    }
}