/**
 * Grid
 * 
 * .full-container
 * +-------------------------------------------------+
 * |                                                 |
 * |                                                 |
 * |                                                 |
 * +-------------------------------------------------+
 * |                                                 |
 * |   .container                                    |
 * |   +---+---------------------------------+---+   |
 * |   |///|.flex-row                        |///|   |
 * |   |/+-------------------------------------+/|   |
 * |   |/|.flex-col         .flex-col        |\|/|   |
 * |   |/+------------------+------------------+/|   |
 * |   |/|/|.bg-gray      |/|/|              |/|/|   |
 * |   |/|/+--------------+/|/|              |/|/|   |
 * |   |/|/|SSSSSSSSSSSSSS|/|/|              |/|/|   |
 * |   |/|/|S            S|/|/|              |/|/|   |
 * |   |/|/|SSSSSSSSSSSSSS|/|/|              |/|/|   |
 * |   +-+-+--------------+-+------------------+-+   |
 * |                                                 |
 * |   .container                                    |
 * |   +-----------------------------------------+   |
 * |   |///|.bg-gray (con margine negativo)  |///|   |
 * |   |/+-------------------------------------+/|   |
 * |   |/|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|/|   |
 * |   |/|SS                                 SS|/|   |
 * |   |/|SS                                 SS|/|   |
 * |   |/|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|/|   |
 * |   |/+-------------------------------------+/|   |
 * |   |///|.bg-gray                         |///|   |
 * |   |///+---------------------------------+///|   |
 * |   |///|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|///|   |
 * |   |///|SS                             SS|///|   |
 * |   |///|SS                             SS|///|   |
 * |   |///|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|///|   |
 * |   |///+---------------------------------+///|   |
 * |   |///|                                 |///|   |
 * |   +-----------------------------------------+   |
 * |                                                 |
 * +-------------------------------------------------+
 * 
 */

// Il padding delle colonne compensa il margine negativo delle righe
%column-padding {
  padding-left: $column-padding;
  padding-right: $column-padding;
}

// A primo impatto, questa regola creava solo problemi (la versione che dava problemi è quella -$column-padding)
// Cosa faceva? In qualsiasi momento in cui c'era un 2-3-4 colonne, non boxed, creava overflow-x
%row-margin {
  margin-left: $column-padding;
  margin-right: $column-padding;
  // margin-left: -$column-padding;
  // margin-right: -$column-padding;
}

// Full container
.full-container {
  width: 100%;
}

// Container
.container {
  width: 100%;
  padding: 0 $container-padding;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $small){
      padding-left: 1.3rem !important;
      padding-right: 1.3rem !important;
      max-width: $small;
      .two-columns, .three-columns, .five-columns{
        .flex-small{
          padding-left: 10px;
          padding-right: 10px;
        }
      }
  }

  @include medium-breakpoint {
      max-width: $medium;
  }

  @include large-breakpoint {
      max-width: $large;
  }

  @include x-large-breakpoint {
      max-width: $x-large;
  }
}

.row {
  @extend %row-margin;
}

.col {
  @extend %column-padding;
}

.flex-row {
  @extend %row-margin;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-small,
.flex-large {
  @extend %column-padding;
  flex-basis: 100%;
  margin-bottom: $margin-bottom;
}

.flex-small,
.flex-large {

  // NodeTypes Columns
  &.five-columns-column {
	flex-basis: 100%;
  }
  &.four-columns-column {
      flex-basis: 25%;
  }
}

.five-columns{
  justify-content: center;
}

/* Small screen breakpoint */

@include small-breakpoint {
  .flex-small {
    margin-bottom: 0;
    // NodeTypes Columns
    &.five-columns-column {
      flex: 0 0 20%;
    }

    &.three-columns-column {
      flex-basis: 33.33%;
    }
    &.two-columns-column {
      flex-basis: 50%;
    }
  }
}

@media screen and (max-width: $medium) {
  .flex-small {
    &.four-columns-column {
      flex-basis: 50%;
    }
  }
}

@media screen and (max-width: $small) {
  .flex-small {
    &.four-columns-column {
      flex-basis: 100%;
    }
  }
}

@media screen and (min-width: #{$mobile}) and (max-width: #{$tablet}){
  .flex-small, .flex-large {
    &.five-columns-column {
      flex: 0 0 50%;
      margin-bottom: 1rem;
    }
  }
}

/* Large screen breakpoint */

@include large-breakpoint {
  .flex-large {
      margin-bottom: 0;

      // NodeTypes Columns
      &.four-columns-column {
          flex-basis: 25%;
      }

      &.three-columns-column {
          flex-basis: 33.33%;
      }

      &.two-columns-column {
          flex-basis: 50%;
      }
  }
}

.four-columns{
  margin: 0;
}


// Questo serve per quando c'è un doppia colonna dentro un doppia colonna -> fa si che si vedda giusto per tablet
@media (max-width: $large) {
  .two-columns {
      .two-columns {
          .two-columns-column {
              flex-basis: 100% !important;
              margin-bottom: 20px;
          }
      }
  }
}



.top-bar {
  display: flex;
  z-index: 101;
  position: relative;
  width: 100% !important;
  align-items: center;
  justify-content: flex-end;
  background-color: #0A0A0A;
  padding-top: 10px;
  padding-bottom: 10px;

  .text-grey,
  a {
      font-size: 13px;
      transition: all 0.2s ease-in-out;

      &:hover {
          color: white;
          text-decoration: none !important;
      }
  }

  .float-items-right {
      .adam-column-row>.neos-contentcollection {
          justify-content: flex-end;

          .adam-image {
              img {
                  width: 25px;
              }
          }

          // Toglie padding da text-wrapper: cause overflow x in pagine
          .adam__text-wrapper {
              padding: 0;
          }
      }
  }
}

section.container > .flex-row {
  margin-left: 0;
  margin-right: 0;
}

.adam-column-row>.neos-contentcollection {
  display: flex;
  flex-direction: row;

  &>section {
      width: fit-content;
  }
}

.column-row-wrap{
  >.column-row{
    >.adam-column-row {
      >.neos-contentcollection{
        flex-wrap: wrap;
      }
    }
  }
}

.float-items-center {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: center;
  }
}

.float-items-right {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: flex-end;

      [class*="negative-margin-"] {
          margin-right: 0;
          padding-right: 0;
      }
  }
}

.float-items-left {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: flex-start;

      [class*="negative-margin-"] {
          margin-left: 0;
          padding-left: 0;
      }
  }
}

.float-items-evenly {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: space-evenly;
  }
}

.float-items-between {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: space-between;
  }
}

.float-items-right {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: flex-end;
  }
}

.float-items-around {
  > .column-row > .adam-column-row > .neos-contentcollection {
      justify-content: space-around;
  }
}

@media (max-width: $small) {
  .top-bar {
    &-menu{
      .neos-contentcollection{
        justify-content: space-between !important;
      }
    }
  }
  .float-items-center {
      .adam-column-row>.neos-contentcollection {
          justify-content: center;
      }
  }

  .float-items-right {
      .adam-column-row>.neos-contentcollection {
          justify-content: flex-end;
      }
  }

  .float-items-left {
      .adam-column-row>.neos-contentcollection {
          justify-content: flex-start;
      }
  }
}

.align-items-center{
  > .column-row{
    >.adam-column-row{
      >.neos-contentcollection{
        align-items: center;
      }
    }
  }
}

.align-items-top{
  > .column-row{
    >.adam-column-row{
      >.neos-contentcollection{
        align-items: flex-start;
      }
    }
  }
}

.align-items-bottom{
  > .column-row{
    >.adam-column-row{
      >.neos-contentcollection{
        align-items: flex-end;
      }
    }
  }
}