.flowpack-searchplugin-search {
    color: $color-white;
    font-family: 'Titillium Web', sans-serif;

    &>form {
        position: relative;
        margin-bottom: 20px;

        &::before {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath d='M18.9 17c-.8.7-1.1 1.1-1.9 1.8-1-1.2-2-2.3-2.9-3.4-.8-1-1.5-1.1-2.7-.5-3.2 1.6-6.9.6-9.1-2.3-2-2.6-1.9-6.5.2-9.1C4.7.8 8.4 0 11.6 1.5c3.6 1.7 5.2 6 3.4 9.8-.5 1.1-.5 1.8.5 2.6 1 .9 2.1 1.9 3.4 3.1zM8.3 2.8c-3.1 0-5.5 2.5-5.5 5.5s2.4 5.3 5.4 5.4 5.4-2.3 5.5-5.3c0-3.1-2.4-5.6-5.4-5.6z' fill='%23767676'/%3E%3C/svg%3E");
            background-size: 20px 20px;
            content: " ";
            display: block;
            position: absolute;
            left: 18px;
            top: 13px;
            height: 20px;
            width: 20px;
        }

        &::after {
            content: "";
            clear: both;
            display: block;
        }

        input[type="search"] {
            border-radius: 0;
            float: left;
            height: 44px;
            padding-right: 130px;
            padding-left: 50px;
            width: 80%;
        }

        button {
            background-color: $color-secondary-dark;
            border: none;
            border-radius: 0;
            color: $color-secondary;
            float: left;
            font-family: klavika-web-condensed, sans-serif;
            font-size: .8125rem;
            letter-spacing: .0625rem;
            margin: 0;
            padding: 13px 0;
            height: 44px;
            text-transform: uppercase;
            transition: all .3s;
            width: 20%;

            &:active,
            &:hover,
            &:focus {
                background-color: $color-secondary;
                border: none;
                color: $color-white;
            }
        }
    }

    &>ol {
        display: grid;
        list-style: none;
        padding: 0;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        margin-bottom: 30px;

        &>li {
            background-color: $color-background-grey;
            padding: 30px;
            position: relative;

            &>a {
                font-size: 1.75rem;
                font-weight: 700;
                line-height: 1.5rem;
            }

            &>p {
                padding-top: 15px;
                padding-bottom: 20px;

                &>em {
                    color: $color-secondary;
                    font-weight: 700;
                    font-style: normal;
                }
            }

            &>.breadcrumb {
                bottom: 20px;
                color: $color-secondary;
                font-size: .8rem;
                position: absolute;

                &>span {
                    display: none;
                }

                &>a {
                    color: $color-secondary;

                    &:last-child {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    &>.page-navigation .neos-widget-paginator {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        gap: 15px;
        list-style: none;
        justify-content: center;
        padding: 0;

        &>li {
            color: $color-primary;
            font-weight: 700;
            padding: 10px;

            &>a {
                color: $color-secondary;
                text-transform: uppercase;
            }
        }
    }

    @media screen and (max-width: $large) {
        &>ol {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: $small) {
        &>ol {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}