// Ferrari colors
$color-primary: #1a2732;
$color-secondary: #35c4b5;
$color-third: #45A29E;
$color-active: #006b81;
$color-white: #fff;
$color-border: #b6c8d6;

$color-primary-light: #394a58;
$color-secondary-dark: #45A29E;

$color-background-alt: #F2F6FB;
$color-background-grey: #f3f4f4;

$color-blue: #004288;
$color-light-blue: #d0dde8;

$color-grey: #8996A0;
$color-light-grey: #acbcc8;

// Override
$primary-color: $color-primary;
$secondary-color: $color-secondary;
$accent-color: $color-grey;

$heading-font-weight: 500;

$background-colors: (
	"primary": $color-primary,
	"secondary": $color-secondary,
	"alternate": $color-background-alt,
    "white": $color-white,
    "primary-alternate": $color-primary-light,
    "secondary-alternate": $color-secondary-dark,
    "primary-bubbles": $color-primary,
    "primary-bubbles-content": $color-primary,
    "light-grey": $color-background-grey,
);

$item-padding-scale: (
	"none": 0,
	"s10": 10px,
	"s20": 20px,
	"s30": 30px,
	"s40": 40px,
	"s50": 50px,
);

// Responsive breakpoints
$x-small: 600px;
$small: 768px;
$medium: 1024px;
$large: 1200px;
$x-large: 1400px;