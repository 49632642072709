// klavika-web-condensed --> Font da usare. NON USARE Klavika Condensed e basta, oppure Klavika-web e basta
p {
    font-size: 16px;
    line-height: 24px;
}

label {
    font-weight: 400;
}

h1,
h2,
h4 {
    font-family: klavika-web-condensed, sans-serif;

    // &>span {
    //     line-height: unset;
    // }
}

// h3,
// h5,
// h6 {
//     &>span {
//         line-height: unset;
//     }
// }

h1,
h2 {
    font-weight: 500;
    line-height: 87px;
    // letter-spacing: 2.61px;
}

.h1,
.h2 {
    line-height: 87px;
    // letter-spacing: 2.61px;
}


// h4,
// h6 {
//     line-height: 20px;
//     font-weight: 400;
// }

h4, .h4{
    font-size: 57px;
    line-height: 57px;
}

h3 {
    font-weight: 600;
    line-height: 28px;
}

.h3 {
    line-height: 28px;
}

h5 {
    font-weight: 500;
    line-height: 24px;
}

.h3 {
    font-size: 24px;
    line-height: 28px;
}

.h5 {
    font-family: klavika-web-condensed, sans-serif;
    letter-spacing: 1.6px;
}


h1,h2,h3,h4,h5,h6{
    span.h1,
    span.h2,
    span.h3,
    span.h4,
    span.h5,
    span.h6{
        display: inline-block;
    }
}

h1,h2,h3,h4,h5,h6{
    span{
        line-height: inherit;
    }
}

@media screen and (max-width: $medium) {

    h1,
    h2 {
        font-size: 55px;
        line-height: 55px;
    }

    .big-font-ferrari {
        font-size: 6rem;
        line-height: 5rem;
    }
}

@media screen and (max-width: $small) {

    h1,
    h2 {
        font-size: 2.5rem;
        line-height: 35px;
    }
}