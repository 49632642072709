@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.core";

.glide {
	position: relative;
}
.glide__arrows {
	display: flex;
	align-items: center;
	.glide__bullets {
		flex-grow: 1;
		text-align: center;
		.glide__bullet {
			margin: 0 .25rem;
		}
	}
}

.glide__bullet {
	position: relative;
}
.glide__bullet::before {
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: transform .15s ease-in-out;
	width: 8px;
	height: 8px;
	background: white;
	border-radius: 100%;
}
.glide__bullet--active::before {
	transform: translate(-50%, -50%) scale(1);
}

button.glide__arrow {
	margin-bottom: 0;
}

@media all and (min-width: 1440px) {
	button.glide__arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	button.glide__arrow--left {
		left: -5rem;
	}
	button.glide__arrow--right {
		right: -5rem;
	}
}

//
// HERO VERSION
//

.glide--hero {
	.glide__track {
		margin-bottom: 0;
	}
	.glide__arrows {
		display: block;
	}
	.glide__bullet {
		color: inherit;
		background: white;
	}
	.glide__bullet::before {
		background: currentColor;
	}
	button.glide__arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		background: white;
		color: inherit;
		border-radius: 0;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	button.glide__arrow--left {
		left: 0;
	}
	button.glide__arrow--right {
		right: 0;
	}
	.glide__bullets {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		margin: 1rem 0;
	}
}
