.accordion {
    padding: 20px 20px 90px 20px;

    &-white {
        background-color: white;
    }

    &-grey {
        background-color: $color-background-grey;
    }

    &-burger {
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        display: flex;

        h3 {
            color: $color-secondary;
            transition: color 0.3s;

            &:hover {
                color: $color-secondary-dark;
                cursor: pointer;
            }
        }

        i {
            font-size: 30px;
            margin-left: 20px;
            color: $color-secondary;
            transition: color 0.3s;

            &:hover {
                color: $color-secondary-dark;
                cursor: pointer;
            }
        }
    }

    &-container {
        margin-left: 1rem;
        margin-right: 1rem;

        &.pdfList {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 50px;
        }
    }

    &.collapsed {
        height: 340px;

        .accordion-column {
            .accordion-container {
                overflow: hidden;
                height: 245px !important;
                border-bottom: 3px solid $color-secondary;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    box-shadow: inset 0 -30px 30px -40px rgba(0, 0, 0, 30%);
                    width: 100%;
                    height: 200px;
                    cursor: row-resize;
                }
            }
        }
    }

}

@media screen and (max-width: $large) {
    .accordion {
        &-container {
            &.pdfList {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .accordion {

        padding: 0;
        padding-bottom: 40px;

        &-white {
            .accordion-burger {
                padding-left: 0;
            }

            .accordion-container {
                margin: 0;
            }
        }

        &-container {
            &.pdfList {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

@media screen and (max-width: $small) {
    .accordion {
        &-container {
            &.pdfList {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}