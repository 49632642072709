.brand {
	padding-right: $padding;
	line-height: 0;
}

.main-menu-toggler {
	margin: $margins 0;
	padding: .5rem;
	font-size: 2rem;
}

.desktop-menu{
	justify-content: flex-end;
}

.site-navbar {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.menu {
	list-style: none;
	margin: 0;
	width: 100%;
	padding: $margins 0;
	// background-color: $alternate-background;
}
.menu--level-0 {
	padding: 0;
	// background-color: $background;
}

.menu-item {
	padding: 0 20px;
}

.menu-item-link {
	display: block;
	padding: 12px 0;
	text-decoration: none !important;
	color: $light-font-color;
}



.menu-item--level-0 {
	> .menu-item-link {
		color: $font-color;
	}
}

.menu-item--has-sub-items {
	.menu-item-link > i {
		padding-left: 5px;
	}
}
@include medium-breakpoint {
	.menu-item--has-sub-items {
		padding-left: 25px;
	}
}

@include medium-breakpoint {
	.menu-item--level-0 {
		> .menu-item-link {
			padding: $padding * 2 0;
		}
	}
	.site-navbar {
		flex-wrap: nowrap;
		> * {
			flex-grow: 1;
		}
	}
	.brand {
		flex-grow: 0;
	}
	.main-menu {
		flex-grow: 1;
	}
	.menu {
		width: auto;
	}
	.menu-item {
		position: relative;
	}
	.menu-item-link {
		white-space: nowrap;
	}

	.menu--level-0 {
		display: flex;
	}
	.menu--level-1 {
		// position: absolute;
		// z-index: 1;
		// background: $background;
		// top: 100%;
		// // left: 50%;
		// // transform: translateX(-50%);
		// left: 0;
		// box-shadow: 0px 12px 12px rgba(0,0,0,.3);
	}
}

span.menu-item-link {
	padding-top: 32px !important;
	color: $disabled-font-color;
}
@media (max-width: $large) {

	span.menu-item-link{
		padding: 10px 0 10px 0 !important;
	}
	.language-menu{
		span.menu-item-link{
			padding: 2px 0 !important;
		}	
	}
	.mobile-menu{
		li{
			text-align: right;
		}
	}
}

