@include only-small-breakpoint {
	.hide-mobile {
		display: none !important;
	}
}

@include small-breakpoint {
	.hide-desktop {
		display: none !important;
	}
}

@each $value, $multiplier in $spacing-scale {
	.padding-top-#{$value} {
		padding-top: $mobile-spacing-scale-unit * $multiplier;
	}
	.padding-bottom-#{$value} {
		padding-bottom: $mobile-spacing-scale-unit * $multiplier;
	}
	.margin-top-#{$value} {
		margin-top: $mobile-spacing-scale-unit * $multiplier;
	}
	.margin-bottom-#{$value} {
		margin-bottom: $mobile-spacing-scale-unit * $multiplier;
	}
}

@include small-breakpoint {
	@each $value, $multiplier in $spacing-scale {
		.padding-top-#{$value} {
			padding-top: $spacing-scale-unit * $multiplier;
		}
		.padding-bottom-#{$value} {
			padding-bottom: $spacing-scale-unit * $multiplier;
		}
		.margin-top-#{$value} {
			margin-top: $spacing-scale-unit * $multiplier;
		}
		.margin-bottom-#{$value} {
			margin-bottom: $spacing-scale-unit * $multiplier;
		}
	}
}

.align-items-center {
	align-items: center;
}
.align-items-bottom {
	align-items: flex-end;
}

.squared-columns {
    #{$has-background} {
        padding: 1.2rem;
    }

    >.two-columns {
        margin-left: 0;
        margin-right: 0;

        >.two-columns-column {
            padding-left: 0;
			&:first-child{
				padding-right: 2rem;
			}
			&:last-child{
				padding-right: 0;
				padding-left: 2rem;
			}

			@media screen and (max-width: $small) {
				&:first-child, &:last-child{
					padding-left: 0;
					padding-right: 0;
				}
			}

            .container:first-child {
                padding: 0 5rem;

                @media screen and (max-width: $large) {
                    padding: 0 2rem;
                }
            }

            @media screen and (max-width: $small) {
                margin-bottom: 0;
				&:first-child{
					padding-right: 0;
				}
            }
        }
    }

	.three-columns {
        margin-left: 0;

        >.three-columns-column {
            padding-left: 0;

            >.container:first-child {
                padding: 0 5rem;

                @media screen and (max-width: $large) {
                    padding: 0 2rem;
                }
            }

            @media screen and (max-width: $small) {
                margin-bottom: 0;
				padding-right: 0;
				padding-left: 0;
            }
        }
    }

	.four-columns{
		>.four-columns-column{
			padding-left: 0;
			padding-right: 2rem;
			@media screen and (max-width: $medium) {
				&:nth-child(2), &:nth-child(4){
					padding-left: 2rem;
					padding-right: 0;
				}
			}
			@media screen and (max-width: $small) {
				padding-left: 0;
				padding-right: 0;
				&:nth-child(2), &:nth-child(4){
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
}


@media screen and (max-width: $small) {
	.invert{
		flex-direction: column-reverse;
		.column-one, .column-two{
			width: 100%;
		}
	}
}

.ferrari-table{
	font-family: 'Titillium Web', sans-serif;
	td, tr{
		border: 1px solid white;
		border-color: white;
	}
	&-header{
		background-color: #F3F4F4;
		font-weight: 500;
		&-nb{
			border-bottom: 0;
		}
	}
	&-first{
		background-color: #D0DDE8;
	}
	&-second{
		color: white;
		background-color: #8996A0;
	}
	&.padding{
		td, th{
			padding: 0.5rem 1.5rem;
		}
	}
}
